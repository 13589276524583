.visa-form-page .home-visa-tab {
    padding-top: 0px;
}
.visa-form-page ul li button:before {
    display: none !important;
}
.visa-form-page .home-visa-tab ul {
    margin: 0;
    padding: 0 !important;
    border: none;
}
.visa-form-page .home-visa-tab ul li {
    margin: 0 !important;
}
.home-visa-tab .nav-tabs .nav-link span img {
    margin-left: 18px;
}
.home-visa-tab .nav-tabs .nav-link.nav-link.active::after {
    display: none;
}
.visa-form-page .home-visa-tab ul li button {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 140%;
    color: #252D43 !important;
    padding: 16px 20px !important;
    background-color: transparent;
    text-transform: initial !important;
    position: relative;
    padding-right: 22px !important;
    font-family: 'Inter' !important;
    letter-spacing: -0.03em;
    border: none;
}
.visa-form-page .home-visa-tab ul li .nav-link.active {
    background-color: white;
    color: #EC8525 !important;
    font-family: 'DM Sans' !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}
.visa-form-page .home-visa-tab ul li button .locked-tab {
    color: #9B9B9B;
}
.visa-form-page .visa-btn button {
    padding: 13px 58px;
    background: #f35c5c;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
    border-radius: 65px;
    border: 1px solid #f35c5c;
    transition: all ease 0.5s;
}
.visa-form-page .visa-btn .visa-desc-text {
    margin-left: 30px;
}
.visa-form-page .visa-btn .visa-desc-text p {
    margin: 0;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #9B9B9B;
}
.visa-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 35px;
}
.personal-info .box-grid-three {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 15px 20px;
    align-items: flex-start;
}
.personal-info .box-grid-two {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px 20px;
}
.visa-form-page button.accordion-button.collapsed,
.visa-form-page .accordion-collapse.collapse.show {
    color: #222222;
    padding: 0;
}
.visa-form-page .accordion-button:not(.collapsed),
.visa-form-page .accord-open.card-header {
    padding: 0px 10px;
    gap: 10px;
    height: 40px;
    border-radius: 10px !important;
    background: #F8FAFC!important;
    margin: 12px 0px 20px 0px;
}
.visa-form-page .accord-open.card-header {
    font-weight: 400;
    text-transform: capitalize;
    font-family: 'Inter';
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #252D43;
}
.visa-form-page .accordion-body {
    padding: 0;
}
.personal-info .accordion-item label,
.personal-info label {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #9B9B9B;
    margin-bottom: 5px;
}
.visa-form-page .form-group {
    margin-bottom: 20px;
}
.personal-info .accordion-item input,
.personal-info .card-body input {
    padding: 9px 10px;
    height: 40px;
    border: 1px solid #ced0d0;
    box-shadow: 0px 0px 0px 2px rgba(238, 238, 238, 0.499809);
    width: 100%;
    border-radius: 6px;
}
.personal-info select#formBasicEmail {
    margin: 0;
    height: 40px;
}
.personal-info .accordion-item input:focus,
.personal-info .accordion-item select:focus,
.personal-info .card-body input:focus,
.personal-info .card-body select:focus {
    color: #252D43;
    background-color: #fff;
    border-color: #EC8525;
    border-style: solid;
    outline: 0;
    border-width: 1.5px;
    box-shadow: 0px 0px 0px 0.25rem rgb(236 133 37 / 25%);
    border-radius: 6px;
}
.personal-info button.accordion-button {
    text-transform: capitalize;
    background: transparent;
    border: none !important;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #252D43;
}
.personal-info .accordion-item,
.personal-info .card {
    background: #ffffff;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: none;
    padding: 8px  20px;
    margin-bottom: 15px;
}
.personal-info.personal-info-tab .accordion-item {
    border-top-left-radius: 0;
}
.lang-section .accordion-item {
    padding: 20px;
}
.lang-section .accordion-item .form-group:last-child {
    margin-bottom: 0;
}
.lang-section  .visa-btn {
    margin-top: 78px;
}
.personal-info .error-msg {
    padding-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #F35C5C;
}
.personal-info .doc-error {
    position: absolute;
    bottom: -24px;
}
.personal-info .error-msg p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 0;
}
.personal-info .error-msg p span {
    font-weight: 600;
    margin-left: 5px;
}
.personal-info .accordion-item .error-msg input,
.personal-info .accordion-item .error-msg select,
.personal-info .card-body .error-msg input,
.personal-info .card-body .error-msg select {
    border-color: #f35c5c;
    border-width: 1.5px;
    box-shadow: 0 0 0 0.25rem rgba(255, 78, 78, 0.25);
}
.personal-info .accordion-button:not(.collapsed)::after {
    background-image: url("../../assets/images/visa/up-arrow.svg");
    transform: none;
    height: 10px;
    width: 25px;
    background-size: contain;
}
.personal-info .accordion-button::after {
    background-image: url("../../assets/images/visa/add-icon.svg");
    width: 41px;
    background-size: cover;
    height: 40px;
}
.personal-info .inner-accordion .accordion-button:not(.collapsed)::after {
    background-image: url("../../assets/images/visa/accord-arrowup.svg");
    width: 15px;
    background-size: cover;
    height: 8px;
    margin-right: 2px;
}
.personal-info .inner-accordion .accordion-button::after {
    background-image: url("../../assets/images/visa/accord-arrow.svg");
    width: 15px;
    background-size: cover;
    height: 9px;
}
.personal-info .child-heading {
    padding: 8px 10px;
    height: 42px;
    background: #f8f9fa;
    border-radius: 6px;
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    color: #333333;
    margin-bottom: 20px;
}
.personal-info .add-btn {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.personal-info .add-btn button {
    padding: 10px 15px;
    height: 40px;
    background: rgba(243, 92, 92, 0.1);
    border-radius: 28.5217px;
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}
.personal-info .add-btn button i {
    font-weight: 700;
    font-size: 17.8261px;
    line-height: 29px;
    text-align: center;
    color: #f35c5c;
    margin-left: 10px;
}
.personal-info .add-btn .delete-btn {
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    text-transform: capitalize;
    color: #333333;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    height: 40px;
    background: #f8f9fa;
    border-radius: 28.5217px;
    margin-right: 20px;
}
.personal-info .add-btn button:hover,
.personal-info .add-btn button:focus {
    outline: none;
    box-shadow: none;
}
.education-info-section .education-info {
    margin-bottom: 20px;
}
.outside-country,
.outside-country h6 {
    margin-bottom: 20px;
}
.inside-country h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    text-transform: capitalize;
    color: #333333;
    margin: 20px 0px;
}
.work-exp-section .add-btn {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.document-info .disclaimer-text p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.442553px;
    color: #6e6f6f;
    margin: 20px 0px;
}
.document-info .disclaimer-text p span {
    font-weight: 700;
}
.document-info .inner-accordion.accordion .accordion-item {
    background: transparent;
    box-shadow: none;
    padding: 20px 10px;
    border-bottom: 2px solid rgb(206 208 208 / 20%);
    border-radius: 0px;
    margin: 0;
}
.document-info .inner-accordion.accordion:first-child .accordion-item {
    padding-top: 0;
}
.document-info .inner-accordion.accordion {
    padding-right: 18px;
}
.visa-form-page .document-info .inner-accordion.accordion button.accordion-button {
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 160%;
    text-transform: capitalize;
    /* color: #6243ce; */
    background: transparent !important;
    padding: 0px;
    margin: 0px;
    margin-bottom: 0px;
}
.document-info .inner-accordion.accordion button.accordion-button.collapsed {
    color: #333333;
    font-family: 'DM Sans';
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.03em;
}
.document-info .inner-accordion.accordion:nth-last-child(2) .accordion-item {
    border: none !important;
}
.document-info .general-info-listing {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 28px;
    gap: 10px;
}
.document-info .general-info-listing .right-info {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 25px;
}
.document-info .general-info-listing .right-info input {
    height: 0px !important;
    width: 0px !important;
    padding: 0px !important;
    position: absolute;
    opacity: 0;
    visibility: hidden;
}
.document-info .general-info-listing .right-info h6 {
    margin: 0px !important;
    font-weight: 400;
    font-size: 15.0943px;
    line-height: 150%;
    color: #ced0d0;
}
.document-info .general-info-listing .right-info img {
    cursor: pointer;
}
.document-info .general-info-listing .right-info:hover h6 {
    color: #f35c5c;
}
.document-info .general-info-listing .right-info label {
    margin: 0px !important;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.document-info .general-info-listing .right-info label h6 {
    margin-right: 25px !important;
}
.document-info .general-info-listing .left-info h6 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.442553px;
    color: #333333;
    margin: 0;
}
.document-info .general-info-listing .middle-info h6 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.442553px;
    color: #6e6f6f;
    margin: 0;
}
.document-info .general-info {
    margin-top: 20px;
}
.document-info .general-info .left-info {
    width: 45%;
}
.document-info .general-info .middle-info {
    width: 35%;
}
.document-info .general-info .right-info {
    width: 20%;
    cursor: pointer;
    position: relative;
}
.documents-popup {
    position: fixed;
    z-index: 999;
    background: white;
    padding: 20px;
    left: 50%;
    top: 50%;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    transform: translate(-50%, -50%);
    border-radius: 12px;
    max-width: 555px;
    width: 100%;
    margin: 0px auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.documents-popup img {
    cursor: pointer;
}
.documents-content {
    display: flex;
    align-items: flex-start;
    gap: 14px;
}
.documents-popup p {
    font-size: 16px;
    line-height: 145.5%;
    letter-spacing: -0.442553px;
    width: 100%;
    font-family: 'Lato';
    font-weight: 400;
    color: #252D43;
    margin: 0;
}
.documents-popup p span {
    color: #EC8525;
    font-weight: 700;
}
.bg-shadow {
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0px;
    right: 0px;
    bottom: 0;
}
.add-business h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    text-transform: capitalize;
    color: #333333;
    margin: 0px 0px 20px;
}
.adaptability-info h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    text-transform: capitalize;
    color: #333333;
    margin-bottom: 20px;
}
.adaptability-bg {
    padding: 20px;
    background: white;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(7px);
    border-radius: 10px;
}
.select-visatype {
    padding: 50px;
    background: white;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(7px);
}
.select-visatype h2 {
    text-align: center;
    font-family: 'Inter' !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #9B9B9B;
}
.right-info:hover svg path {
    fill: #f35c5c;
}
/* .visa-form-page .tab-content .tab-pane:first-child .personal-info .accordion-item:first-child,
.visa-form-page .tab-content .tab-pane:first-child .personal-info .card:first-child {
    border-top-left-radius: 0px;
} */

/* comment new card design when form closed or filled */

/* .personal-info.personal-info-tab .accordion-item, .personal-info.personal-info-tab .card {
    margin-bottom: 0;
    margin-top: 15px;
}
.personal-info.personal-info-tab .accordion-item {
    margin: 0;
    padding:  20px;
}
.personal-info.personal-info-tab .accordion-list.change-accordion-icon  .card{
    margin: 0;
    border-radius: 0;
    padding:  20px;
    position: relative;
}
.personal-info.personal-info-tab .accordion-list.change-accordion-icon .accord-open.card-header {
    margin-top: 0;
}
.personal-info.personal-info-tab .accordion-list.change-accordion-icon .card::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 95%;
    background: rgb(155 155 155 / 20%);
    top: 0;
}
.personal-info.personal-info-tab .accordion-list.change-accordion-icon  .card-body {
    padding: 0;
} */
.accordion-list.change-accordion-icon .accordion-button::after {
    background-image: url(../../assets/images/visa/close-arrow.svg) !important;
    transform: none;
    height: 10px;
    width: 25px;
    background-size: contain;
}
.accordion-list.change-accordion-icon .accordion-button:not(.collapsed)::after {
    background-image: url(../../assets/images/visa/orange-arrow.svg) !important;
}
.delete-btn button:hover {
    background: #f8f9fa;
    border-radius: 28.5217px;
    color: #EC8525;
}
.accordion-button:not(.collapsed) button:hover {
    background: white;
    border-radius: 28.5217px;
    color: #EC8525;
}
.delete-btn {
    margin-left: 10px;
}
button.active {
    color: red;
}
.form-sponsor button,
.form-sponsor button:hover,
.form-sponsor button:focus {
    background: transparent;
    border: 1px solid red;
    border-radius: 5px !important;
    margin-right: 20px;
    padding: 8px 15px;
    background: transparent;
    box-shadow: none !important;
    border-radius: 65px;
    border: 1px solid #f35c5c;
    border-radius: 20px !important;
    color: #f35c5c;
    outline: none;
}
.form-sponsor {
    margin-bottom: 30px;
}
.form-sponsor .btn-primary.active:focus {
    box-shadow: none !important;
}
.form-sponsor button.active {
    background: #f35c5c;
    color: white;
    border: 1px solid #f35c5c;
    border-radius: 20px !important;
}
.support-visa-form {
    padding: 0 0 50px;
}
.support-visa-form .home-visa-tab {
    position: relative;
}
.support-visa-form .home-visa-tab .download-btn {
    position: absolute;
    right: 0;
}
.support-visa-form .home-visa-tab .download-btn button {
    background: transparent;
    border: 1px solid red;
    border-radius: 5px !important;
    margin-right: 20px;
    padding: 8px 15px;
    background: #f35c5c;
    box-shadow: 0px 4px 6px -1px rgb(0 0 0 / 12%), 0px 2px 4px -1px rgb(0 0 0 / 7%);
    border-radius: 65px;
    border: 1px solid #f35c5c;
    border-radius: 20px !important;
}
.support-visa-form * {
    font-family: "Lato", sans-serif !important;
}
.support-visa-form .la,
.support-visa-form .las {
    font-family: "Line Awesome Free" !important;
}
.visa-btn button:hover {
    background: #6243ce;
    border: 1px solid #6243ce;
}
.visa-btn button:focus {
    background: #ced0d0 !important;
    border-color: #ced0d0 !important;
}
.language-heading {
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    padding-top: 20px;
}
@media screen and (max-width: 768px) {
    .visa-form-page .home-visa-tab ul {
        margin: 0;
        padding: 0 !important;
        display: flex;
        white-space: nowrap;
        flex-wrap: nowrap;
        overflow: auto;
    }
    .visa-form-page .home-visa-tab ul::-webkit-scrollbar {
        height: 4px;
        border-radius: 4px;
    }
    .visa-form-page .home-visa-tab ul::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    .personal-info .box-grid-two {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 10px;
        margin-top: 10px;
    }
    .personal-info .box-grid-three {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 10px;
        margin-top: 10;
    }
    .visa-form-page .form-group {
        margin: 0;
    }
    .visa-btn {
        flex-wrap: wrap;
        margin-top: 20px;
        gap: 10px;
    }
    .visa-form-page .visa-btn .visa-desc-text {
        margin: 0;
    }
    .document-info .general-info-listing .right-info h6 {
        display: none;
    }
    .document-info .general-info-listing .left-info h6 {
        font-size: 14px;
        font-weight: 600;
    }
    .document-info .general-info-listing .middle-info h6 {
        font-size: 12px;
        font-weight: 600;
    }
}
.upload-block,
.upload-block * {
    cursor: not-allowed;
}
.upload-block:hover * {
    color: #ced0d0 !important;
    fill: #ced0d0 !important;
}
.popup-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.documents-popup .visa-btn {
    justify-content: center;
}
.visa-btn button.btn.btn-primary {
    padding: 7px 58px;
    box-shadow: 0px 4px 6px -1px rgb(0 0 0 / 12%), 0px 2px 4px -1px rgb(0 0 0 / 7%);
    border-radius: 65px;
    border: 1px solid #EC8525;
    transition: all ease 0.5s;
    background: #EC8525;
    height: 45px;
}
.accordion-list button.btn.btn-primary,
.accordion-list button.btn.btn-primary:focus {
    background: none;
    border: none;
    padding: 0;
    border-radius: 20px;
    right: 0;
    box-shadow: none;
}
.personal-info .card-header {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 160%;
}
.personal-info .accordian-title {
    display: flex;
    align-items: center;
    font-family: 'Inter';
}
.card-header img.arrow-icon {
    width: 25px;
    height: 10px;
    margin-right: 4px;
}
.error-modal .modal-body {
    min-height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}
.accord-cardbody-scnd {
    padding: 0;
}
.for-new-changes {
    background: white;
    padding: 20px;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(7px);
    border-radius: 12px;
    width: fit-content;
}
.for-new-changes p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    line-height: 145.5%;
    letter-spacing: -0.442553px;
    color: #252D43;
    margin: 0;
}
.for-new-changes p span {
    color: #EC8525;
    font-weight: 700;
}
.personal-info span.address-subheading {
    font-weight: 400;
    font-size: 14px;
    font-family: 'Inter';
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #9B9B9B;
    margin-bottom: 20px;
    display: inline-block;
}
.personal-info .address-heading {
    background: #f8f9fa;
    border-radius: 6px;
    padding: 8px 10px;
    margin-top: 20px;
}
.address-heading h5 {
    font-size: 16px;
    margin: 0;
}
.personal-info.address-heading h5 {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-transform: capitalize;
    color: #333333;
}
.add-btn button svg {
    width: 13px;
    height: 100%;
    margin-left: 10px;
    fill: #EC8525;
}
.add-btn button.btn.btn-primary,
.add-btn button.btn.btn-primary:focus {
    height: 40px;
    background: #F8FAFC;
    border-radius: 28.5217px;
    padding: 9px 14px;
}
.tab-subheading {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #6e6f6f;
    margin-bottom: 20px;
    display: inline-block;
}
