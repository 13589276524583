.auth-page {
    background: #252D43;
    display: flex;
    justify-content: center;
   
    min-height: 100vh;
}
.auth-header {
    padding: 40px 0px;
    max-width: 120px;
    width: 100%;
    margin: 0px auto;
}
.auth-header img {
    max-width: 120px;
    width: 100%;
}

.auth-box {
    max-width: 466px;
    width: 100%;
    margin: 0px auto;
    padding-top: 130px;
}
.auth-box .nav-tabs {
    margin-bottom: 40px;
    border-bottom: 2px solid rgb(255 255 255 / 10%);
}
.auth-box li.nav-item {
    width: 50%;
}
.auth-box .nav-tabs .nav-link {
    width: 100%;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    border: none;
}
.auth-box .nav-tabs .nav-link.active {
    background: transparent;
    border-bottom: 2px solid #FFFFFF;
}
.auth-form h2 {
    font-family: 'Inter' !important;
    font-weight: 600;
    font-size: 26px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    margin: 0;
    padding-bottom: 40px;
    text-align: center;
}
.auth-form h6 {
    font-family: 'Inter' !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    margin: 0;
    padding-bottom: 5px; 
}
.payment-user-detail .auth-form p{
    font-family: 'Inter' !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: rgb(255 255 255 / 50%);
    margin: 0;
    padding-bottom: 30px; 
}
.payment-user-detail span.text-warning {
    display: block;
    padding-top: 5px;
    font-size: 14px;
}
.auth-form-group {
    margin-bottom: 10px;
}
.auth-form-group .form-label {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.auth-form-group input.form-control {
    background: #FFFFFF;
    border: 1px solid #9B9B9B;
    border-radius: 3px;
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #252D43;
}
form .react-tel-input .form-control {
    width: 100%;
    padding-left : 50px;
}
.auth-form-group input.form-control::placeholder {
    color: #9B9B9B;
}
.payment-user-detail .auth-box {
    padding-top: 20px;
}
.forgot-text a {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    text-decoration: none;
}
.forgot-text a:hover {
    color: #EC8525;
}
.auth-form-btn {
    max-width: 180px;
    width: 100%;
    margin: 0px auto;
    padding-top: 40px;
}
.auth-form button {
    background: #EC8525;
    border-radius: 3px;
    width: 100%;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    height: 45px;
}

.auth-form button:hover, .auth-form button:first-child:active, .auth-form button:focus, .auth-form button:first-child:focus-visible {
    box-shadow: none;
    background: #EC8525;
    color: #FFFFFF;
    border: none;
}
.forgot-password .forgot-text {
    padding-top: 20px;
    text-align: center;
}
.pass-show {
    position: relative;
}
.pass-icon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 6px;
}
.error-msg {
    padding-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #F35C5C;
}

/* **************************************************** */
.footer {
    background: var(--secondry-color);
  }
  
  .footer-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 70px 0 60px;
  }
  
  .footer-box h2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 120%;
    letter-spacing: -0.03em;
    text-transform: capitalize;
    color: var(--primary-color);
    margin: 0;
    padding-bottom: 20px;
  }
  
  .footer-box ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .footer-box ul li {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: var(--primary-color);
    padding-bottom: 20px;
    cursor: pointer;
    transition: all 0.5s;
    display: flex;
    width: fit-content;
  }
  
  .footer-box ul li:hover {
    color: var(--accent-color);
  }
  
  .footer-box ul li a {
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: -0.03em;
    color: var(--primary-color) !important;
    text-decoration: underline;
  }
  
  .footer-box-anchor {
    text-decoration: none;
  }
  
  .footer-box ul li p {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #ffffff;
    margin: 0;
    max-width: 200px;
  }
  
  .footer-box input {
    width: 309px;
    height: 39px;
    color: var(--primary-color);
    border: 1px solid #eaeaea;
    border-radius: 3px;
  }
  
  .border-btn button {
    width: 141px;
    height: 39px;
    border: 1px solid var(--accent-color);
    border-radius: 3px;
    background: transparent;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: var(--accent-color);
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
  }
  
  .border-btn button:hover,
  .border-btn button:focus,
  .border-btn button:active {
    background-color: var(--accent-color) !important;
    color: var(--primary-color) !important;
    border: 1px solid var(--accent-color) !important;
  }
  
  .social-icon {
    display: flex;
    align-items: center;
    gap: 24px;
  }
  
  .social-icon svg {
    transition: all 0.5s;
  }
  
  .social-icon svg:hover {
    scale: 1.2;
  }
  
  .social-icon svg:hover path {
    fill: var(--accent-color);
  }
  
  .footer-card {
    display: flex;
    gap: 12px;
  }
  
  .footer-copyright {
    padding-bottom: 20px;
  }
  
  .footer-copyright p {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #9b9b9b;
    opacity: 0.9;
    margin: 0;
  }
  
  .footer-box {
    width: 100%;
  }
  
  .footer-logo {
    max-width: 276px;
    width: 100%;
  }
  
  .footer-content-right {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
  }
  
  .footer-box ul.social-content {
    padding-bottom: 20px;
  }
  
  .social-icon li {
    padding-bottom: 0 !important;
  }
  .privacy-page {
    padding-top: 20px;
  }
  
  @media screen and (max-width: 991px) {
    .footer-content-right {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  
    .footer-content {
      flex-direction: column;
      align-items: center;
      gap: 50px;
    }
  
    .footer-logo {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .footer-box input {
      max-width: 309px;
      width: 100%;
    }
  }
  
 
  





@media screen and (max-width: 991px) {
    .auth-header {
        padding: 20px 0px;
    }
    .auth-box {
        padding-top: 125px;
    }
    .auth-box .nav-tabs {
        margin-bottom: 30px;
    }
    .auth-form-btn {
        max-width: 218px;
    }
    .auth-form h2 {
        padding-bottom: 30px;
    }
  }

  @media screen and (max-width: 767px) {
    .footer-content {
      align-items: center;
      justify-content: center;
      gap: 40px;
      width: 100%;
      text-align: center;
      padding-bottom: 40px;
    }
  
    .footer-content-right {
      flex-wrap: wrap;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  
    .footer-logo {
      margin: 0 auto;
    }
  
    .footer-box ul li p {
      margin: 0 auto;
    }
  
    .footer-box input {
      max-width: 100%;
      height: 55px;
    }
  
    .border-btn button {
      width: 100%;
      height: 55px;
    }
  
    .footer-card {
      align-items: center;
      justify-content: center;
      padding-top: 20px;
    }
    .footer-box h2 {
      color: #9b9b9b;
    }
    .footer-box h2.social-heading {
      padding-bottom: 10px;
    }
    .footer-box h2.newsletter-heading {
      color: var(--primary-color);
    }
    .footer-box ul li {
      width: 100%;
      padding-bottom: 10px;
      justify-content: center;
    }
    .footer-box ul li a {
      font-size: 26px;
      line-height: 140%;
      text-align: center;
    }
    .footer-box ul.social-content li {
      padding-bottom: 0px;
    }
    .footer-box ul.social-content {
      padding: 0;
    }
    .footer-box ul.social-content li a {
      font-size: 14px;
      line-height: 120%;
      text-align: center;
      color: #ffffff !important;
    }
    .social-icon {
      justify-content: center;
    }
    .social-icon li {
      width: unset !important;
    }
    .border-btn {
      width: 100%;
    }
    .privacy-page li a {
      font-size: 20px !important;
    }
    .footer-box h2 {
      padding-bottom: 10px;
    }
  }