@import "https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap";
@import "https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap";

.my-application-page {
    background: #F1F4FA;
    font-family: 'DM Sans', sans-serif;
}
.my-application-page * {
    font-family: 'DM Sans', sans-serif;
}
.my-application-page h1, .my-application-page h2, .my-application-page h3, .my-application-page h4, .my-application-page h5, .my-application-page h6 {
    font-family: 'DM Sans', sans-serif !important;
}
.my-application-page a{ 
    text-decoration: none;
}
.my-application-page ._access-icon {
    display: none;
}
.my-application {
    position: relative;
    max-width: 100%;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: calc(100vh - 10vh);
}
.application-sidebar {
    max-width: 336px;
    width: 100%;
    padding: 24px 0px 24px 0px;
    position: fixed;
    border-right: 1px solid rgb(103 116 142 / 10%);
    background: #ffffff;
    box-shadow: 0px 20px 27px rgb(0 0 0 / 5%);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    min-height: 100vh;
    bottom: 0;
    top: 0;
}
.my-application-grid {
    width: calc(100% - 336px);
    padding: 10px 43px 23px 10px;
    margin-left: 336px;
    height: 100%;
}
.my-application-grid .container {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0px !important;
}
.application-logo {
    padding-bottom: 50px;
    text-align: center;
}
.my-application-content {
    padding-right: 100px;
    min-height: calc(100vh - 323px);
    height: 100%;
}
.application-sidebar .navbar-expand .navbar-nav {
    flex-direction: column;
}
.application-sidebar .navbar-light .navbar-nav a,
.mob-sidebar .navbar-light .navbar-nav a {
    padding: 0;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.03em;
    text-transform: capitalize;
    transition: all 0.3s;
    color: #252D43;
    display: flex;
    align-items: center;
    text-decoration: none;
}
.application-sidebar .navbar-light .navbar-nav a:hover,
.mob-sidebar .navbar-light .navbar-nav a:hover {
    color: #f35c5c !important;
}
.application-sidebar .navbar-light .navbar-nav a span,
.mob-sidebar .navbar-light .navbar-nav a span {
    width: 56px;
    height: 56px;
    line-height: 56px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}
.application-sidebar .navbar-light .navbar-nav a.active,
.mob-sidebar .navbar-light .navbar-nav a.active {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #f35c5c;
    background: #ffffff;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
    border-radius: 59px;
    padding-right: 20px;
}
.mob-sidebar .navbar-light .navbar-nav a img.active-img {
    display: none;
}
.mob-sidebar .navbar-light .navbar-nav a.active img.active-img {
    display: block;
}
.mob-sidebar .navbar-light .navbar-nav a.active img.non-active-img {
    display: none;
}
.application-sidebar .navbar-light .navbar-nav a.active span,
.mob-sidebar .navbar-light .navbar-nav a.active span {
    background: transparent;
    box-shadow: none;
}
.application-sidebar .navbar-light .navbar-nav a.active span svg path,
.mob-sidebar .navbar-light .navbar-nav a.active span svg path {
    fill: #f35c5c !important;
}
.application-sidebar span svg path,
.mob-sidebar span svg path {
    fill: #9B9B9B;
}
.application-logout {
    position: absolute;
    /* bottom: 55px; */
    padding-left: 64px;
    padding-top: 35px;
}
.application-logout span {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #252D43;
    padding: 0px 18px;
    cursor: pointer;
}
.application-logout span img {
    margin-right: 34px;
}
.application-header-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 10px;
}
.application-menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 64px;
}
.my-application-grid .application-menu {
    height: 173px;
    /* background: linear-gradient(91.47deg, #ffffff 2.47%, #f1edff 102.06%);
    box-shadow: 0px 20px 27px rgb(0 0 0 / 5%); */
    background: #FFFFFF;
    backdrop-filter: blur(7px);
    width: calc(100% - 100px);
    padding: 20px;
    position: relative;
    border-radius: 10px;
}
.my-application-grid .application-menu .bg-image {
    position: absolute;
    right: 0;
    top: -15px;
    bottom: 0;
    display: flex;
    align-items: center;
}
.my-application-grid .application-menu .bg-image img {
    height: 100%;
    width: fit-content;
    object-fit: cover;
}
.my-application-grid .application-menu h6 {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 22px;
    line-height: 120%;
    letter-spacing: -0.03em;
    text-transform: capitalize;
    color: #252D43;
    padding-bottom: 10px;
}
.my-application-grid .application-menu p {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #9B9B9B;
    margin: 0;
    font-family: 'Inter';
}
.my-application-grid .application-menu .top-header-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.bottom-content span {
    font-weight: 700;
}
.my-application-grid .application-menu p {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #6e6f6f;
    margin: 0;
}
.my-application-grid .application-header-grid {
    align-items: flex-start;
}
.application-case {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.application-action {
    width: 100px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    padding-top: 20px;
}
.application-action span {
    margin-left: 30px;
    cursor: pointer;
}
.application-action span a {
    position: relative;
}
.application-action span p {
    margin: 0;
    background: rgba(236, 133, 37, 0.1);
    border-radius: 18.2857px;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 11.4286px;
    line-height: 18px;
    text-align: center;
    color: #EC8525;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -18px;
    right: -10px;
}
.application-noti {
    position: relative;
}
.count {
    position: absolute;
    width: 20px;
    height: 20px;
    right: -16px;
    top: -14px;
    font-weight: 700;
    font-size: 11.4286px;
    line-height: 18px;
    text-align: center;
    color: #f35c5c;
    background: rgba(243, 92, 92, 0.1);
    border-radius: 18.2857px;
}
.application-user img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    margin-right: 10px;
}
.application-user span {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #3a416f;
    display: inline-block;
}
.application-user {
    margin-right: 27px;
}
.application-case span {
    display: inline-block;
    margin: 0px 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #b8b8b8;
}
.application-case-icon {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #67748e;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #333333;
}
.application-case-working {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #67748e;
}
.application-footer-menu ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
    align-items: center;
    padding-bottom: 20px;
}
.application-footer-menu ul li a {
    transition: all 0.5s;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #6e6f6f;
}
.application-footer-menu ul li a:hover {
    color: #EC8525 !important;
}
.application-footer-menu ul li {
    margin-right: 45px;
}
.application-footer {
    position: relative;
    padding-top: 27px;
}
.application-footer-menu p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.387234px;
    color: #6e6f6f;
    margin: 0;
    display: flex;
    align-items: center;
}
.application-footer-menu p img {
    margin-left: 8px;
}
.welcome-page {
    position: relative;
    min-height: 62vh;
}
.scroll-content {
    max-height: 100%;
    overflow-y: auto;
}
.scroll-content::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgb(254 254 254);
}
.scroll-content::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(255, 255, 255, 0.8);
}
.scroll-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #afb7c4;
}
.welcome-page-content {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 20px 27px rgb(0 0 0 / 5%);
    border-radius: 10px;
    padding: 20px;
}
.welcome-page h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    text-transform: capitalize;
    color: #222222;
    margin: 0;
    padding-bottom: 30px;
}
.welcome-page-content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
    margin-bottom: 1.5rem;
    font-family: 'Inter';
}
.welcome-page-content p:first-child {
    font-size: 20px;
    line-height: 28px;
}
.welcome-page-content span {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #222222;
}
.regards-sign {
    margin-bottom: 18px;
}
.guidelines-page {
    position: relative;
}
.guidelines-page-content {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 20px 27px rgb(0 0 0 / 5%);
    border-radius: 10px;
    padding: 20px 18px;
}
.guidelines-page h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 160%;
    text-transform: capitalize;
    color: #222222;
    margin: 0;
    padding-bottom: 20px;
}
.guidelines-page-content h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.03em;
    margin: 0;
    padding-bottom: 10px;
    padding-top: 0px;
    color: #252D43;
}
.guidelines-page-content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #252D43;
    margin-bottom: 20px;
    font-family: 'Inter';
    letter-spacing: -0.03em;
}
.guidelines-page-content p:first-child {
    max-width: 780px;
    width: 100%;
}
.guidelines-page-content h4 img {
    margin-right: 10px;
}
.guidelines-page-content a {
    text-decoration-line: underline !important;
    margin-bottom: 10px;
    display: inline-block;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #EC8525;
}
.guidelines-page-content a.guideline_desc_link {
    margin-bottom: 0px;
}
.guidelines-page-content li {
    letter-spacing: -0.03em;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #252D43;
    font-family: 'Inter';
}
.guidelines-page-content li::marker {
    color: #EC8525;
}
.guidelines-page-content ul {
    padding-left: 18px;
    max-width: 772px;
    width: 100%;
}
.guidelines-page-content ul.formats-ul {
    max-width: 100%;
}
.digital-photo-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.guideline-letter-select select.form-select {
    border: 1px solid #ced0d0;
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    color: #333333;
    padding: 14px 15px;
    margin: 0;
    background-image: url("../../assets//images/application/down-arrow.svg");
    background-size: inherit;
}
.guideline-letter-select option {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #222222;
}
.guideline-letter-select .form-select:focus {
    box-shadow: none;
}
.guideline-letter-select {
    margin-bottom: 20px;
}
.letter-example ul {
    padding-left: 18px;
    margin-bottom: 10px;
}
form.success-form button {
    background: #ced0d0 !important;
    border: 1px solid #ced0d0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
form.success-form button img {
    margin-left: 8px;
}
.settings-page {
    min-height: 62vh;
    position: relative;
}
.settings-page h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    text-transform: capitalize;
    color: #222222;
    margin: 0;
    padding: 20px 0;
}
.settings-form-content {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 20px;
    padding-bottom: 62px;
}
.settings-form-container {
    max-width: 447px;
}
.settings-page-content .pass-show {
    position: relative;
}
.settings-page-content .pass-icon {
    top: 6px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    display: none;
}
.settings-page-content .edit-input {
    position: relative;
    padding-bottom: 20px;
}
.settings-page-content .edit-icon {
    top: 0px;
    cursor: pointer;
    position: absolute;
    right: -42px;
}
.settings-page-content 
.settings-page-content h4 {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #9B9B9B;
    margin: 0;
    padding-bottom: 5px;
}
.settings-page-content p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #252D43;
    margin: 0;
    padding-bottom: 18px;
}
.settings-page-content form {
    /* max-width: 447px; */
}
.settings-page-content form input,
.settings-page-content form input:disabled {
    background: #ffffff;
    max-width: 447px;
    width: 100%;
    height: 40px;
    border: 1px solid #D3D3D3;
    box-shadow: 0px 0px 0px 2px rgba(238, 238, 238, 0.499809);
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.387234px;
    color: #333333;
    margin-bottom: 20px;
}
.settings-page-content form input:disabled {
    cursor: not-allowed;
}
.settings-page-content form input::placeholder {
    color: #141414;
}
.settings-page-content .btn {
    background: #EC8525;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
    border-radius: 65px;
    border-color: #EC8525;
    max-width: 167px;
    width: 100%;
    height: 44px;
    margin-top: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #ffffff;
}
.settings-page-content .btn:focus,
.settings-page-content .btn-primary:active:focus {
    background: #EC8525;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
    border-color: #EC8525;
}
.settings-page-content label.form-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #6e6f6f;
    margin-bottom: 5px;
}

.settings-page-content button:hover {
    background: #EC8525 !important;
    border-color: #EC8525 !important;
}
.settings-page-content button:focus {
    background: #ced0d0 !important;
    border-color: #ced0d0 !important;
}
.notification-page {
    position: relative;
}
.notification-heading {
    display: flex;
    align-items: center;
}
.notification-heading h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    margin: 0;
    text-transform: capitalize;
    color: #222222;
}
.notification-tab {
    display: flex;
    align-items: center;
    gap: 17px;
}
.notification-tab h6 {
    margin: 0;
    font-size: 16px;
    text-transform: capitalize;
    color: #ced0d0;
    cursor: pointer;
    padding: 11px 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #252D43;
}
.notification-tab h6.active-link {
    color: #EC8525;
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
}
.notification-grid {
    margin-bottom: 20px;
    max-width: 670px;
}
.notification-page-content {
    background: #FFFFFF;
    border-radius: 0px 10px 10px 10px;
    padding: 20px;
    padding-top: 0;
} 
.notification-page-content.cleared {
    border-radius: 10px;
}
.notification-box-new {
    position: relative;
    border-bottom: 2px solid rgb(155 155 155 / 20%);
    padding: 20px 0;
}
.notification-box-new:last-child, .notification-box-cleared:last-child {
    border: none;
}
.notification-box-new p { 
    margin: 0;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    line-height: 137.5%;
    color: #252D43;
    max-width: 600px;
    width: 100%;
}
.notification-box-cleared {
    border-bottom: 2px solid rgb(206 208 208 / 20%);
    padding: 20px 0;
    position: relative;
    opacity: 0.5;
}
.notification-page-content .notification-box:last-child {
    margin-bottom: 0px;
}
.notification-box p {
    font-size: 16px;
    line-height: 137.5%;
    margin: 0;
    font-weight: 400;
    color: #333333;
}
.notification-box-new span.noti-close-btn {
    position: absolute;
    right: 15px;
    top: 14px;
    cursor: pointer;
}
  span.greeting-text {
    padding-top: 20px;
    display: inline-block;
}
.notification-box-cleared span.noti-close-btn {
    position: absolute;
    right: 15px;
    top: 14px;
    cursor: pointer;
}
.notification-box-cleared p {
    margin: 0;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    line-height: 137.5%;
    color: #252D43;
}
.invoices-page {
    position: relative;
}
.invoices-page h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    padding-bottom: 20px;
    margin: 0;
    text-transform: capitalize;
    color: #222222;
}
.invoices-page-content table tr td {
    border: none;
    padding: 20px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.387234px;
    color: #333333;
}
.invoices-page-content td.invoice-colm-name.col {
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
}
.invoices-page-content {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 0 20px;
    /* min-height: 80vh; */
}
.invoices-page-content table.table {
    margin: 0;
}
.invoices-page-content table tr td .invoice-name {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #252D43;
}
.invoices-page-content table tr td .invoice-date {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #252D43;
}
.invoices-page-content table tr td .invoice-date span {
    font-family: 'Inter';
    display: block;
    margin-top: 5px;
    color: #9B9B9B;
}
.invoices-page-content table tr td .invoice-price {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #252D43;
}
.invoices-page-content table tr td .invoice-pdf {
    cursor: pointer;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #252D43;
}
.invoices-page-content table tr td .invoice-pdf:hover {
    color: #EC8525;
}
.invoices-page-content tr {
    border-bottom: 2px solid rgb(206 208 208 / 20%);
}
.invoices-page-content tr:last-child {
    border: none;
}
.application-page {
    position: relative;
    min-height: 62vh;
}
.step-wizzard {
    padding-bottom: 20px;
}
.step-hide {
    display: none;
}
.step-wizzard ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
}
.step-wizzard ul li {
    background: rgb(175 183 196 / 30%);
    border-radius: 65px;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    text-transform: capitalize;
    color: #ffffff;
    padding: 7px 20px;
    margin-right: 30px;
}
.step-wizzard ul li.active {
    background: #ffffff;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
    opacity: 1;
    color: #f5a963;
}
.step-form-content {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    padding: 20px 30px;
    border-radius: 10px;
}
.step-form-grid {
    max-width: 548px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.step-form-grid .form-group {
    max-width: 265px;
    width: 100%;
    margin-bottom: 20px;
}
.step-form-grid .form-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #222222;
    margin-bottom: 13px;
}
.step-form-grid .form-control {
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 0px 2px rgba(238, 238, 238, 0.499809);
    border-radius: 6px;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.387234px;
    color: #141414;
}
.step-form-grid .form-control::placeholder {
    color: #141414;
}
.step-btn {
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.step-btn .btn,
.step-btn .btn {
    background: #f35c5c;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
    border-radius: 65px;
    max-width: 167px;
    width: 100%;
    border-color: #f35c5c;
    height: 50px;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    margin-right: 26px;
}
.step-btn p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.442553px;
    color: #b8b8b8;
    max-width: 390px;
}
.step-form-content table tr td {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.387234px;
    vertical-align: middle;
    color: #222222;
    border-color: #f5f5f5;
    padding: 10px 20px 10px;
}
.step-form-content table tr:last-child td {
    border-color: transparent;
}
.step-form-content table.table {
    margin: 0;
}
.step-form-content table tr td .application-format {
    color: #afb7c4;
    position: relative;
    padding-right: 25px;
}
.step-form-content table tr td .application-format span {
    position: absolute;
    right: 0;
    top: 0;
}
.application-btn span {
    background: #ffffff;
    box-shadow: 0px 3.57143px 5.35714px -0.892857px rgba(0, 0, 0, 0.12), 0px 1.78571px 3.57143px -0.892857px rgba(0, 0, 0, 0.07);
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    margin-right: 15px;
}
.step-form-content table tr td:last-child {
    width: 175px;
}
.application-btn {
    font-weight: 400;
    font-size: 15.0943px;
    line-height: 150%;
    color: #3a416f;
    display: flex;
    align-items: baseline;
    cursor: pointer;
    transition: all 0.5s;
    max-width: 150px;
    width: 100%;
}
.application-btn:hover {
    background: #ffffff;
    box-shadow: 0px 4px 6px -1px rgb(0 0 0 / 12%), 0px 2px 4px -1px rgb(0 0 0 / 7%);
    border-radius: 59px;
}
.application-btn:hover span {
    box-shadow: none;
}
.application-btn:hover span svg path {
    fill: #6243ce !important;
}
.application-attached {
    font-weight: 400;
    font-size: 15.0943px;
    line-height: 150%;
    color: #3a416f;
    text-align: right;
    padding: 10px 0px;
}
.document-info .general-info-listing .application-attached {
    padding: 0;
    font-weight: 400;
    font-size: 15.0943px;
    line-height: 150%;
    color: #ced0d0;
}
.document-info .general-info-listing .documents-attached-section {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.document-info .general-info-listing .documents-attached-section span {
    margin-left: 22px;
}
.application-attached img {
    margin-left: 20px;
}
.application-warning {
    background: rgb(175 183 196 / 20%);
    border-radius: 12px;
    padding: 20px;
    position: relative;
    padding-left: 51px;
    margin-bottom: 10px;
}
.application-warning p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.442553px;
    color: #141414;
}
.application-warning img {
    position: absolute;
    left: 20px;
    top: 24px;
}
.application-thank-you {
    text-align: center;
}
.application-thank-you .step-form-content {
    padding: 50px;
}
.application-thank-you p {
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: #222222;
    max-width: 400px;
    margin: 0px auto;
}
.application-thank-you h2 {
    font-weight: 700;
    font-size: 26px;
    line-height: 150%;
    color: #333333;
    margin: 0;
    padding-bottom: 10px;
    padding-top: 10px;
}
.application-page .upload-file-section input {
    height: 0px;
    width: 0px;
    visibility: hidden;
    opacity: 0;
}
.application-page .upload-file-section label {
    max-width: 150px;
    width: 100%;
}
.application-action .mob-icon,
.mob-step-btn {
    display: none;
}
.settings-mob-view.navbar-nav {
    display: none;
}
.application-action span.mob-icon {
    display: none;
}
.invoices-page-content.scroll-content .table-responsive {
    height: 100%;
    overflow: initial;
}
.invoices-page table.table_space.table {
    min-height: 80vh;
}
.invoices-page-content.scroll-content .dropdown-menu {
    background: white;
    border: 1px solid #e9e7e7;
    left: -6rem !important;
    inset: initial;
    transform: none;
    width: 12rem;
}
.invoices-page-content.scroll-content .dropdown-item.active,
.dropdown-item:active {
    background-color: transparent !important;
}
.custom-dropdown h6 {
    padding: 10px 19px;
    min-width: 224px;
    height: 57px;
    width: fit-content;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 0px auto;
}
.custom-dropdown h6 span {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #252D43;
}
.custom-dropdown img.dropdown-avatar {
    border-radius: 50%;
}
.custom-dropdown ul {
    position: absolute;
    background: white;
    width: 100%;
    padding: 0px !important;
    z-index: 9999;
    border-radius: 5px;
    max-height: 180px;
    box-shadow: 0px 20px 27px rgb(0 0 0 / 5%);
    overflow: auto;
}
.custom-dropdown h6 img:first-child {
    margin-right: 24px;
}
.custom-dropdown h6 img:last-child {
    margin-left: 18px;
}
.custom-dropdown {
    position: relative;
    min-width: 224px;
    padding-bottom: 50px;
    margin: 0px auto;
    max-width: 238px;
    width: 100%;
}
.custom-dropdown ul li {
    list-style-type: none;
    padding: 8px 20px;
    cursor: pointer;
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
.progress-bar-section .CircularProgressbar .CircularProgressbar-text {
    fill: #6243ce;
    font-size: 20px;
    text-anchor: middle;
    font-weight: 800;
    font-size: 20.2733px;
    line-height: 20px;
    text-align: center;
}
.progress-bar-section {
    height: 207px;
    width: 207px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}
.progress-bar-section > div {
    height: 100% !important;
    width: 100% !important;
}
.application-case-icon img {
    border-radius: 50%;
    margin-right: 8px;
    width: 27px;
    height: 27px;
    margin-top: 4px;
}
.application-noti a svg path {
    fill: #b3b4b5;
}
.desktop-icon svg path {
    fill: #b3b4b5;
}
.active-link svg path {
    fill: #f35c5c !important;
}
.application-case-mob {
    display: none;
}
.close-icon-mob {
    display: none;
}
.delete-btn button,
.delete-btn button:hover,
.delete-btn button:focus,
.delete-btn .btn-primary:active:focus {
    width: 72px;
    background: #F8FAFC !important;
    border-radius: 28.5217px !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    text-transform: capitalize;
    color: #252D43;
    border: none;
    box-shadow: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
}
.delete-btn button i {
    margin-left: 10px;
    color: #f35c5c;
    line-height: initial;
}
.application-logout:hover svg path {
    fill: #f35c5c;
    transition: all ease 0.5s;
}
.application-logout svg {
    margin-right: 18.5px;
}
@media screen and (max-width: 1024px) {
    .my-application-grid .application-menu .bg-image img {
        height: 120px;
    }
    .progress-bar-section {
        display: none;
    }
    .step-wizzard ul li {
        font-size: 13px;
        margin-right: 20px;
    }
    .application-sidebar .navbar-light .navbar-nav a.active {
        font-size: 13px;
    }
    .application-sidebar .navbar-light .navbar-nav a span {
        width: 42px;
        height: 42px;
    }
    .application-case-working {
        font-size: 14px;
    }
    .application-case-icon {
        font-size: 14px;
    }
    .application-user span {
        font-size: 14px;
    }
    .my-application-content {
        padding-right: 0px;
    }
}
@media screen and (max-width: 768px) {
    .application-sidebar .application-logout {
        display: none;
    }
    .my-application-grid .container {
        padding: 0px 15px !important;
    }
    .settings-mob-view.navbar-nav {
        display: block;
    }
    .my-application {
        flex-direction: column;
        min-height: auto;
    }
    .my-application-grid {
        width: 100%;
        padding: 0;
        margin-left: 0;
    }
    .application-sidebar .application-menu {
        position: absolute;
        max-width: 351px;
        background: #67748e;
        box-shadow: 0px 20px 27px rgb(0 0 0 / 5%);
        backdrop-filter: blur(7px);
        border-radius: 10px;
        z-index: 9999;
        width: 100%;
        margin: 0px auto;
        height: 0px;
        opacity: 0;
        visibility: hidden;
        transition: all ease 0.2s;
        transform: translate(-50%, 0%);
        left: 50%;
        margin-top: 80px;
    }
    .application-sidebar .application-menu .navbar-expand {
        flex-direction: column;
        align-items: flex-start;
    }
    .application-sidebar .settings-mob-view.navbar-nav a {
        margin-bottom: 0;
    }
    .settings-mob-view.navbar-nav {
        margin-top: 97px;
    }
    .application-menu .navbar-expand {
        width: auto;
        padding: 0;
    }
    .settings-mob-view.navbar-nav a span {
        background: transparent !important;
        box-shadow: none !important;
    }
    .settings-mob-view.navbar-nav a {
        color: white;
    }
    .my-application.open-sidebar .application-menu {
        opacity: 1;
        visibility: visible;
        height: auto;
        margin-top: 0;
        background: #ffffff;
        box-shadow: 0px 25px 27px rgb(0 0 0 / 12%);
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
        justify-content: center;
        padding: 40px;
        gap: 40px;
    }
    .application-sidebar .navbar-light .navbar-nav a {
        color: #333;
    }
    .application-sidebar .application-logo {
        padding-bottom: 20px;
        margin: 0px auto;
        text-align: center;
    }
    .application-sidebar {
        max-width: 100%;
        padding: 16px 0px;
        box-shadow: none;
        background: #f8f9fa;
        z-index: 99;
    }
    .application-case span {
        display: none;
    }
    .application-case-icon {
        flex-direction: column;
        font-weight: 600;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #6e6f6f;
    }
    .application-case-icon span {
        padding-left: 5px;
        padding-bottom: 10px;
    }
    .application-case-working {
        font-size: 14px;
    }
    .application-case {
        margin-top: 20px;
        width: 100%;
        border-bottom: 1px solid #67748e21;
    }
    .application-header-grid {
        position: relative;
        padding-bottom: 10px;
    }
    .application-action {
        top: -50px;
        position: absolute;
        right: 0px;
        width: 115px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        z-index: 999;
        padding: 0;
    }
    .application-action span.mob-icon {
        font-size: 26px;
        color: #000000;
        width: 45.88px !important;
        height: 45.88px !important;
        left: 29.46px;
        top: 0px;
        background: #ffffff;
        box-shadow: 0px 3.27734px 4.91602px -0.819336px rgb(0 0 0 / 12%), 0px 1.63867px 3.27734px -0.819336px rgb(0 0 0 / 7%);
        border-radius: 50%;
        text-align: center;
        margin: 0;
    }
    .application-action .mob-icon {
        display: block;
    }
    .application-action .mob-icon i {
        font-size: 26px;
        color: #f35c5c;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    .application-action span {
        margin-left: 0;
        cursor: pointer;
    }
    .application-action span.mob-icon {
        display: block;
    }
    .step-wizzard ul {
        justify-content: center;
    }
    .step-wizzard ul li {
        display: none;
        margin: 0;
    }
    .step-wizzard ul li.active {
        display: block;
    }
    .scroll-content {
        max-height: 100%;
        padding: 20px 15px;
    }
    .step-btn {
        display: none;
    }
    .step-form-grid {
        max-width: 100%;
        width: 100%;
    }
    .step-form-grid .form-group {
        max-width: 100%;
    }
    .mob-step-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    .mob-step-btn .btn-primary:disabled {
        padding: 7px 20px;
        min-width: 162.43px;
        height: 40px;
        background: #afb7c4;
        border: 1px solid #afb7c4;
        opacity: 0.3;
        border-radius: 65px;
        margin-bottom: 10px;
    }
    .mob-step-btn button {
        background: #f5a963;
        box-shadow: 0px 4px 6px -1px rgb(0 0 0 / 12%), 0px 2px 4px -1px rgb(0 0 0 / 7%);
        border-radius: 65px;
        max-width: 167px;
        width: 100%;
        border-color: #f5a963;
        height: 40px;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
        margin-right: 26px;
        margin-bottom: 10px;
    }
    .step-form-content table tr td {
        white-space: nowrap;
    }
    .guidelines-page h3 {
        font-size: 18px;
        padding-bottom: 10px;
    }
    .guidelines-page-content.scroll-content {
        padding: 20px 10px;
        max-height: 531px;
    }
    .invoices-page-content table tr td {
        white-space: nowrap;
    }
    .invoices-page h3 {
        padding-bottom: 10px;
    }
    .invoices-page-content.scroll-content {
        padding: 20px 10px;
    }
    .application-footer-menu ul {
        align-items: flex-start;
        flex-direction: column;
        padding-bottom: 0px;
    }
    .application-footer-menu ul li {
        padding-bottom: 10px;
    }
    .application-footer-menu p {
        margin: 10px 0px;
        justify-content: center;
    }
    .my-application-grid .application-menu .bg-image {
        display: none;
    }
    .custom-dropdown {
        padding-bottom: 0;
        margin-right: auto;
        margin-left: 15px;
        min-width: auto;
        max-width: fit-content;
        width: calc(100% - 155px);
    }
    .custom-dropdown h6 span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
    }
    .custom-dropdown h6 img:first-child {
        margin-right: 1px;
    }
    .custom-dropdown h6 img:last-child {
        margin-left: 1px;
    }
    .application-sidebar {
        min-height: auto;
        height: auto;
        top: initial;
        bottom: initial;
        position: initial;
    }
    .my-application-grid .application-menu {
        display: none;
    }
    .offcanvas-backdrop {
        z-index: 9999;
        background-color: #000;
    }
    .mob-sidebar.offcanvas {
        height: fit-content;
        max-width: 351px;
        width: 100%;
        margin: 0 auto;
        border-radius: 12px 0 12px 12px;
        background: #ffffff;
        box-shadow: 0px 25px 27px rgb(0 0 0 / 12%);
        z-index: 9999;
        top: 112px;
        right: 15px;
    }
    .mob-sidebar .offcanvas-body {
        padding: 60px 0 63px;
    }
    .application-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        gap: 30px;
        padding-left: 0;
    }
    .mob-sidebar .navbar-nav {
        flex-direction: column;
    }
    .application-case-mob {
        display: block;
        padding-bottom: 67px;
    }
    .custom-dropdown h6 {
        width: unset;
        height: unset;
        justify-content: space-between;
        border-radius: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #333333;
        gap: 10px;
        min-width: auto;
        max-width: 100%;
        padding: 8px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
    }
    .close-icon-mob {
        display: block;
        padding-top: 70px;
    }
    .application-logout {
        position: unset;
        bottom: unset;
        padding-top: 70px;
        padding-left: 0;
    }
    .menu-close-btn {
        position: absolute;
        top: -58px;
        right: 0;
        padding: 16px 16px 20px 16px;
        border-radius: 45% 45% 0 0;
        background: #fff;
    }
    .application-logout span {
        padding: 0;
    }
    .box-grid-two .mb-3 {
        margin: 0 !important;
    }
    .work-exp-section .add-btn {
        margin: 20px 0;
    }
    .guideline-letter-select select.form-select {
        font-size: 14px;
    }
    .notification-grid {
        margin-bottom: 20px;
    }
    .settings-page-content .edit-icon {
        right: -20px;
    }
}
.la,
.las {
    font-family: "Line Awesome Free" !important;
}
