.agreement-page-box {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 0px 20px;
}
.agreement-page-content {
    padding: 20px 0px;
    border-bottom: 2px solid rgb(155 155 155 / 20%);
}
.agreement-page-content:last-child {
    border: none;
}
.agreement-page h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    padding-bottom: 20px;
    margin: 0;
    text-transform: capitalize;
    color: #222222;
}
.agreement-list {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ced0d033;
    padding: 20px 0px;
}
.agreement-list:first-child {
    padding-top: 0px;
}
.agreement-list:last-child {
    padding-bottom: 0;
    border: none;
}
.agreement-list .download-section {
    display: flex;
    justify-content: flex-end;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Inter';
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #252D43;
}
.agreement-list .download-section span {
    margin: 0px 20px;
    color: #333333;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}
.agreement-list .download-section .download:hover {
    color: #EC8525;
}
.agreement-list .download-section .download {
    cursor: pointer;
    line-height: initial;
}
.agreement-list .download-section .upload {
    cursor: pointer;
    line-height: initial;
}
.agreement-list h6 {
    margin: 0;
    font-family: 'Inter' !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #252D43;
}
.agreement-list .download-section .upload-doc input {
    opacity: 0;
    height: 0px;
    width: 0px;
    position: absolute;
    visibility: hidden;
}
.agreement-list .download-section .upload-doc label {
    cursor: pointer;
}
.agreement-list .download-section .upload-doc:hover label {
    color: #EC8525;
}
.agreement-list  .attahed-img {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #9B9B9B;
    max-width: 128px;
    width: 100%;
}
.agreement-list .attahed-img img:last-child {
    margin-left: 20px;
    cursor: pointer;
}
.agreement-list .attahed-img img {
    margin-left: 5px;
}
.agreement-list .attahed-img span {
    margin: 0;
}
